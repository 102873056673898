import React from 'react';
import { Container } from './styles';
import NavBar from '../../components/NavBar';
import ImageOverTextBanner from '../../components/ImageOverTextBanner';
import Footer from '../../components/Footer';
import SectionGrey from '../../components/SectionGrey';
import Section from '../../components/Section';
import MagazineCard from '../../components/MagazineCard';

import Brand from '../../assets/img/ufmt-logo.png';
import Logo from '../../assets/img/logo_ufmt_negativo_Prancheta.svg';
import { ReactComponent as Book } from '../../assets/img/book.svg';
import Banner from '../../assets/img/ufmt-library-photo-1600.jpg';
import { magazines } from '../../services/magazines';

export default function Main() {
  return (
    <Container>
      <NavBar
        brandImage={Brand}
        brandText="Portal de Revistas Científicas da UFMT"
      />
      <ImageOverTextBanner image={Banner} />
      <SectionGrey Icon={Book} title="SOBRE O PORTAL">
        <p>
	  O Portal de Revistas Científicas da Universidade Federal de Mato Grosso (UFMT) é uma iniciativa do Curso de Biblioteconomia do Campus Universitário de Rondonópolis (atual UFR), em parceria a Editora da UFMT, e as equipes editoriais dos periódicos científicos da Instituição. Atualmente, o projeto conta com o apoio da Biblioteca Central, da Secretaria de Tecnologia Educacional, das equipes editoriais dos periódicos científicos, da Pró-Reitoria de Pós-Graduação, da Pró-Reitoria de Pesquisa, da Secretaria de Comunicações e Multimeios e da Editora da UFMT. O Portal visa o desenvolvimento, a democratização do acesso ao conhecimento produzido pelas pesquisas científicas e a qualificação dos periódicos científicos da UFMT. Utiliza o Open Journal Systems (OJS), software desenvolvido para a construção e gestão de publicações periódicas eletrônicas, contemplando ações essenciais à automação das atividades de editoração de periódicos científicos.
	</p>
      </SectionGrey>
      <Section title="PERIÓDICOS CIENTÍFICOS">
        <div className="container-fluid">
          <div className="row">
            {magazines?.map(magazine => (
              <div key={magazine.id} className="col-md-4 col-lg-3 col-xl-2">
                <MagazineCard
                  title={magazine.name}
                  url={magazine.url}
                  image={magazine.cover}
                />
              </div>
            ))}
          </div>
        </div>
      </Section>

      <Footer logo={Logo} />
    </Container>
  );
}
